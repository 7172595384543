const loadingStyle = {
  padding: '20px 50px',
  background: ' #003f63',
  fontFamily: ' Roboto, sans-serif !important',
  position: ' absolute',
  top: ' 50%',
  left: ' 50%',
  transform: ' translate(-50%, -50%)',
  display: ' flex',
  flexDirection: ' column',
  zIndex: ' 100',
  justifyContent: ' center',
  alignItems: ' center',
  borderRadius: '10px',
  fontSize: '20px',
}

export default function Loading({ children = 'Loading...' }) {
  return <div style={loadingStyle}>{children}</div>
}
