import React, { lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import './styles/typography.css';
import './styles/index.sass';
import { BrowserRouter } from 'react-router-dom';
// import App from './App'
import Head from './components/Head';
import Loading from './components/Loading';
// import image from './logo512.png'
// import * as serviceWorkerRegistration from './serviceWorkerRegistration'

// import { initialize } from './helpers/analytics'

// initialize()

// const Head = lazy(() => import('./components/Head'))
// const App = lazy(() => import('./App'));

const container = document.getElementById('root');
const root = createRoot(container);

function isIos() {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    navigator.userAgent.includes('Mac')
  );
}

if (isIos()) {
  const appStoreUrl = 'https://apps.apple.com/app/istoriaround/id6448400837';
  window.location = appStoreUrl;
} else {
  const playStoreUrl =
    'https://play.google.com/store/apps/details?id=com.istoriaround.app';
  window.location = playStoreUrl;
}

root.render(
  <BrowserRouter>
    <Head
      title={'App | IstoriAround'}
      description={
        'IstoriAround | Immersi nella storia. Ovunque andiamo, ovunque ci giriamo, siamo circondati da luoghi storici e curiosità, perché non approfondire i nostri dintorni? Con IstoríAround potrai scoprire tutti gli stori intorno a te, e non solo!'
      }
      // imageUrl={image}
    />
    <Suspense fallback={<Loading />}>{/* <App /> */}</Suspense>
  </BrowserRouter>
);

// serviceWorkerRegistration.unregister()
