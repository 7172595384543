import React from 'react'

import { Helmet } from 'react-helmet'

export default function Head({ title, description, imageUrl }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="description" content={description} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:url" content={window.location.href} />
      <meta name="twitter:card" content="summary" />/
      <meta property="og:site_name" content="App | IstoriAround" />
      <meta name="twitter:image:alt" content={`Immagine di ${title}`} />
    </Helmet>
  )
}
